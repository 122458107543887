export const getSidebarFields = (isClient = false) => `
  defaultSidebar {
    upgpCategorySidebarBlocks {
      upgpCategorySibebarBlockName
      upgpCategorySibebarBlockLinks {
        customLinkTitle
        link {
          ... on ${isClient ? 'Post' : 'WpPost'} {
            databaseId
            title
            uri
          }
        }
      }
      upgpCategorySibebarBlockCategory {
        slug
        name
      }
      upgpCategorySibebarBlockTag {
        databaseId
        name
        slug
      }
    }
  }
  overrideSidebar {
    sidebars {
      upgpSidebarOverrideType
      upgpSidebarOverridePostList {
        ... on ${isClient ? 'Post' : 'WpPost'} {
          databaseId
          slug
        }
      }
      upgpSidebarOverrideTagList {
        name
        databaseId
        slug
      }
      upgpSidebarOverrideCatList {
        name
        slug
      }
      upgpSidebarOverrideBlocks {
        upgpCategorySibebarBlockName
        upgpCategorySibebarBlockLinks {
          customLinkTitle
          link {
            ... on ${isClient ? 'Post' : 'WpPost'} {
              databaseId
              uri
              title
            }
            ... on ${isClient ? 'Page' : 'WpPage'} {
              databaseId
              uri
              title
            }
          }
        }
      }
    }
  }
`;
