import { gql } from '@apollo/client';

export const POST_DATA = gql`
  fragment PostData on Post {
    ... on Post {
      id
      databaseId
      uri
      link
      cardDataJsonPreview
      author {
        node {
          databaseId
          postsCount
          description
          name
          uri
          avatar {
            url
          }
          userBios {
            upgpUserShortBio
          }
          userSocials {
            upgpUserEmail
            upgpUserSocialLinks {
              upgpUserSocialLinksName
              upgpUserSocialLinksUrl
            }
          }
          upgpAuthorDetails {
            upgpEditorCustomExcerpt
            upgpCountriesVisited
            upgpUsStatesVisited
            upgpEditorJobTitle
            upgpAuthorCountEditedPosts
          }
        }
      }
      featuredImage {
        node {
          sourceUrl
        }
      }
      featuredImageOptions {
        imageHorizontalPosition
        imageVerticalPosition
      }
      featureImageCredit {
        upgpPostFeaturedImageCreditUrl
        upgpPostFeaturedImageCreditValue
      }
      title
      categories {
        nodes {
          name
          uri
          slug
        }
      }
      commentCount
      commentStatus
      comments {
        nodes {
          id
          parentId
          author {
            node {
              url
              name
              email
              avatar {
                url
              }
            }
          }
          date
          content
        }
      }
      modifiedGmt
      dateGmt
      content
      cardMentionsAll
      nonAffiliateAll
      fieldsForPosts {
        upgpPostFaqs {
          ... on Post_Fieldsforposts_UpgpPostFaqs_UpgpPostFaq {
            upgpPostAnswer
            upgpPostQuestion
          }
        }
        upgpPostRelatedPosts {
          fieldGroupName
          upgpGbPostRelatedPostsPostPage {
            ... on Post {
              id
              title
              uri
              featuredImage {
                node {
                  sourceUrl
                }
              }
            }
          }
        }
        upgpUseTrustDropdown
        upgpPostToggleElements
        upgpOverrideDefaultSidebar
        upgpCategorySidebarBlocks {
          upgpCategorySibebarBlockName
          upgpCategorySibebarBlockLinks {
            customLinkTitle
            link {
              ... on Post {
                databaseId
                title
                uri
              }
            }
          }
        }
      }
      postEditorSettings {
        upgpPostEditor {
          description
          databaseId
          postsCount
          username
          name
          uri
          avatar {
            url
          }
          userBios {
            upgpUserShortBio
          }
          upgpAuthorDetails {
            upgpEditorCustomExcerpt
            upgpCountriesVisited
            upgpUsStatesVisited
            upgpEditorJobTitle
            upgpAuthorCountEditedPosts
          }
        }
        upgpPostEditorTwo {
          description
          databaseId
          postsCount
          username
          name
          uri
          avatar {
            url
          }
          userBios {
            upgpUserShortBio
          }
          upgpAuthorDetails {
            upgpEditorCustomExcerpt
            upgpCountriesVisited
            upgpUsStatesVisited
            upgpEditorJobTitle
            upgpAuthorCountEditedPosts
          }
        }
      }
      seo {
        title
        metaRobotsNofollow
        metaRobotsNoindex
        canonical
        metaDesc
        opengraphDescription
        opengraphImage {
          sourceUrl
          mediaType
          mediaDetails {
            height
            width
          }
        }
        opengraphType
        opengraphTitle
        opengraphUrl
        opengraphSiteName
        opengraphPublisher
        opengraphModifiedTime
        readingTime
        schema {
          raw
        }
      }
      tags {
        nodes {
          name
          slug
          databaseId
        }
      }
      template {
        templateName
        ... on Template_HotelLoyaltyProgram {
          templateName
          hotelLoyaltyProgramDetails {
            upgpHotelLoyaltyProgramEarnPointsmiles
            upgpHotelLoyaltyProgramBrands
            upgpHotelLoyaltyProgramAwardChartLink
            upgpHotelLoyaltyProgramName
            upgpHotelLoyaltyProgramPointsAreWorth
            upgpHotelLoyaltyProgramRedeemPointsmiles
            upgpHotelLoyaltyProgramReview
            upgpHotelLoyaltyProgramSummaryTableTitle
            upgpHotelLoyaltyProgramWebsite
          }
        }
        ... on Template_AirlineLoyaltyProgram {
          templateName
          airlineLoyaltyProgramDetails {
            upgpAirlineLoyaltyProgramAirlineAlliance
            upgpAirlineLoyaltyProgramFees
            upgpAirlineLoyaltyProgramEarnPointsmiles
            upgpAirlineLoyaltyProgramHomeCountry
            upgpAirlineLoyaltyProgramMajorHubs
            upgpAirlineLoyaltyProgramName
            upgpAirlineLoyaltyProgramPointsAreWorth
            upgpAirlineLoyaltyProgramRedeemPointsmiles
            upgpAirlineLoyaltyProgramReview
            upgpAirlineLoyaltyProgramSignatureLounge
            upgpAirlineLoyaltyProgramSummaryTableTitle
            upgpAirlineLoyaltyProgramWebsite
            upgpAirlineLoyaltyProgramAwardChartLink
          }
        }
      }
      fieldsForCreditCards {
        upgpCreditCardCons
        upgpCreditCardPros
        upgpCreditCardQuickSummary
        upgpCreditCardImageGroup {
          upgpCreditCardImage {
            sourceUrl
          }
        }
        upgpCreditCardShortReview
        upgpCreditCardUpRating
      }
    }
  }
`;