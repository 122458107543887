import { gql } from '@apollo/client';

export const PAGE_DATA = gql`
  fragment PageData on Page {
    ... on Page {
      databaseId
      title
      content
      featuredImage {
        node {
          sourceUrl
        }
      }
      featureImageCredit {
        upgpPostFeaturedImageCreditUrl
        upgpPostFeaturedImageCreditValue
      }
      uri
      commentCount
      commentStatus
      comments {
        nodes {
          id
          parentId
          author {
            node {
              url
              name
              email
              avatar {
                url
              }
            }
          }
          date
          content
        }
      }
      modifiedGmt
      dateGmt
      author {
        node {
          databaseId
          description
          postsCount
          name
          uri
          avatar {
            url
          }
          userBios {
            upgpUserShortBio
          }
          userSocials {
            upgpUserEmail
            upgpUserSocialLinks {
              upgpUserSocialLinksName
              upgpUserSocialLinksUrl
            }
          }
          upgpAuthorDetails {
            upgpEditorCustomExcerpt
            upgpCountriesVisited
            upgpUsStatesVisited
            upgpEditorJobTitle
            upgpAuthorCountEditedPosts
          }
        }
      }
      cardMentionsAll
      nonAffiliateAll
      fieldsForPosts {
        upgpPostFaqs {
          ... on Page_Fieldsforposts_UpgpPostFaqs_UpgpPostFaq {
            upgpPostAnswer
            upgpPostQuestion
          }
        }
        upgpPostRelatedPosts {
          fieldGroupName
          upgpGbPostRelatedPostsPostPage {
            ... on Post {
              id
              title
              uri
              featuredImage {
                node {
                  sourceUrl
                }
              }
            }
          }
        }
        upgpUseTrustDropdown
        upgpPostToggleElements
        upgpOverrideDefaultSidebar
        upgpCategorySidebarBlocks {
          upgpCategorySibebarBlockName
          upgpCategorySibebarBlockLinks {
            customLinkTitle
            link {
              ... on Post {
                databaseId
                title
                uri
              }
            }
          }
        }
      }
      seo {
        title
        metaRobotsNofollow
        metaRobotsNoindex
        canonical
        metaDesc
        opengraphDescription
        opengraphImage {
          sourceUrl
          mediaType
          mediaDetails {
            height
            width
          }
        }
        opengraphType
        opengraphTitle
        opengraphUrl
        opengraphSiteName
        opengraphPublisher
        opengraphModifiedTime
        readingTime
        schema {
          raw
        }
      }
      template {
        templateName
      }
    }
  }
`;