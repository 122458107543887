import React, { FC, useEffect } from 'react';
import { PreviewData } from '../pages/preview';

interface IPreviewWrapper {
  data: PreviewData | null;
}

const PreviewWrapper: FC<IPreviewWrapper> = ({ data, children }) => {
  useEffect(() => {
    // After fetching data, we wait for 1 second for the DOM content to render before we attach events to the Tooltip Icon
    const timeout = setTimeout(() => {
      if (data) {
        const tooltipIcons = document.querySelectorAll('.tooltipIcon') || [];
        tooltipIcons.forEach((node) => {
          node.addEventListener('click', (e) => {
            const tooltipIcon = e.currentTarget as Element;
            const tooltipText = tooltipIcon.nextSibling as HTMLSpanElement;
            if (tooltipText) {
              if (tooltipText.style.display && tooltipText.style.display === 'block') {
                tooltipText.style.display = 'none';
              } else {
                tooltipText.style.display = 'block';
              }
            }
          });
        });
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [data]);
  return <div className="upgp-preview-page">{children}</div>;
};

export default PreviewWrapper;
