import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { processHTMLContent } from '../../utils/processHTMLContent';
import Seo from '../../components/Seo';
import DisclosuresESI from '../../components/DisclosuresESI/DisclosuresESI';
import TopSlider from '../../components/TopSlider';
import { createTemplateContext } from '../../utils/helpers';
import { HT_DEFAULT_PAGE_CATEGORY, HT_DEFAULT_PAGE_NAME } from '../../constants';
import HTPageWrapper from '../../wrappers/HTPageWrapper';

interface FooterAndHeaderOnlyTemplateProps {
  page: Queries.WpPage;
}

const FooterAndHeaderOnlyTemplate = ({
  data,
  location,
}: PageProps<FooterAndHeaderOnlyTemplateProps>) => {
  const {
    page: { content = '', uri, cardMentionsAll, nonAffiliateAll, databaseId, title },
  } = data;

  const context = createTemplateContext(data);

  const renderContent = () => {
    return (
      <HTPageWrapper
        category={HT_DEFAULT_PAGE_CATEGORY}
        title={title || HT_DEFAULT_PAGE_NAME}
        location={location}
      >
        <TopSlider context={context} />
        {processHTMLContent(content || '')}
      </HTPageWrapper>
    );
  };

  if (uri === '/join-newsletter/') {
    return (
      <section className="footer-and-header-only">
        <div className="container">
          {renderContent()}
          <DisclosuresESI
            parentId={databaseId}
            cardMentions={cardMentionsAll}
            nonAffiliate={nonAffiliateAll}
          />
        </div>
      </section>
    );
  }
  return renderContent();
};

export default FooterAndHeaderOnlyTemplate;

export const Head = ({ data }: PageProps<FooterAndHeaderOnlyTemplateProps>) => {
  const { title, seo } = data.page;
  const needToAddOrgSchema = title === 'Home';
  return <Seo wpSeo={seo} needToAddOrgSchema={needToAddOrgSchema} />;
};

export const pageQuery = graphql`
  query FooterAndHeaderOnlyTemplate($id: String) {
    page: wpPage(id: { eq: $id }) {
      ...PageData
    }
  }
`;
