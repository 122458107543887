import React, { useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import WpDefaultPostTemplate from '../templates/WpPost/WpDefaultTemplate';
import AirlineLoyaltyProgramTemplate from '../templates/WpPost/AirlineLoyaltyProgram';
import HotelLoyaltyProgramTemplate from '../templates/WpPost/HotelLoyaltyProgram';
import CreditCardReviewTemplate from '../templates/WpPost/CreditCardReview';
import PaidLandingPage from '../templates/WpPage/PaidLandingPage';
import PaidLandingPageFullWidth from '../templates/WpPage/PaidLandingPageFullWidth';
import Membership from '../templates/WpPage/Membership';
import NoSidebar from '../templates/WpPage/NoSidebar';
import CleanLandingPageWithTopNav from '../templates/WpPage/CleanLandingPageWithTopNav';
import FooterAndHeaderOnly from '../templates/WpPage/FooterAndHeaderOnly';
// @ts-ignore
import client from '../gatsby-plugin-apollo/client';
// @ts-ignore
import { POST_DATA } from '../fragments/post-data.gql';
// @ts-ignore
import { PAGE_DATA } from '../fragments/page-data.gql';
import WpDefaultPageTemplate from '../templates/WpPage/WpDefaultTemplate';
import { getSidebarFields } from '../fragments/sidebar-fields';
import PreviewWrapper from '../wrappers/PreviewWrapper';
import { isLocalStorageAllowed } from '../utils/helpers/localStorage';

export interface PreviewData {
  contentNode: {
    __typename: string;
    template: {
      __typename: string;
    };
  };
  sidebar: object;
}

/**
 * A functional component that renders a private preview.
 *
 * @param data The data from the query.
 * @returns A react component with Hello World!.
 */
const Preview = () => {
  const [data, setData] = useState(null as PreviewData | null);
  const [message, setMessage] = useState(null as string | null);

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    const p = params.get('p');
    const token = params.get('token') || '';
    if (isLocalStorageAllowed()) {
      window.localStorage.setItem('token', token);
    }
    
    client
      .query({
        query: gql`
          ${POST_DATA}
          ${PAGE_DATA}
          query {
            contentNode(id: ${p}, idType: DATABASE_ID) {
              ...PostData
              ...PageData
            }
            sidebar: upOption(id: "cG9zdDo0NDExODk=") {
              ${getSidebarFields(true)}
            }
          }
        `,
      })
      .then((result: any) => setData(result.data))
      .catch((networkError: any) => {
        console.log(networkError);
        setMessage('Your session has expired. Please re-generate the preview of this post.');
      });
  }, []);

  if (message) {
    return <h2>{message}</h2>;
  }

  if (!data?.contentNode?.__typename) {
    return (
      <span className="loadingAnimation">
        <svg xmlns="http://www.w3.org/2000/svg" width="114.847" height="66.317">
          <g fill="#fff">
            <path d="m55.865 19.693-.06.129h.056Z"></path>
            <path d="m60.071 0-6.2 13.388-.06.129-5.646 12.172h5.512v16.669c0 7.487-2.221 13.381-7.185 17.6S35.034 66.32 27.055 66.32q-12.093 0-19.59-6.362T0 42.4V0h15.217v42.4c0 4.028 1.043 7.1 3.151 9.158s4.981 3.1 8.719 3.1c3.672 0 6.554-1.016 8.61-3.071s3.071-5.1 3.071-9.184V0Z"></path>
            <path d="M108.024 5.933Q101.198 0 89.57 0h-29.5l4.549 9.825 1.71 3.692 5.645 12.172h-5.545v39.685h13.353V42.949h9.788c7.76 0 13.9-1.946 18.454-5.9s6.823-9.1 6.823-15.546a19.646 19.646 0 0 0-6.823-15.57ZM98.81 28.608c-1.73 1.81-4.25 2.716-7.54 2.716h-9.785V11.691h9.788q4.981 0 7.567 2.8a10 10 0 0 1 2.58 7.129 9.769 9.769 0 0 1-2.61 6.988Z"></path>
          </g>
        </svg>
      </span>
    );
  }

  const contentType = data?.contentNode?.__typename;

  if (contentType === 'Post') {
    const postComponentMap: any = {
      Template_AirlineLoyaltyProgram: AirlineLoyaltyProgramTemplate,
      Template_HotelLoyaltyProgram: HotelLoyaltyProgramTemplate,
      Template_CreditCardReview: CreditCardReviewTemplate,
    };

    const template = data?.contentNode?.template?.__typename;
    const TemplateComponent =
      typeof template === 'string'
        ? postComponentMap[template] || WpDefaultPostTemplate
        : WpDefaultPostTemplate;
    const { contentNode: post, sidebar } = data;
    return (
      <PreviewWrapper data={data}>
        <TemplateComponent data={{ post: post, isPreview: true }} pageContext={{ sidebar }} />
      </PreviewWrapper>
    );
  }

  if (contentType === 'Page') {
    const pageComponentMap: any = {
      Template_PaidLandingPage: PaidLandingPage,
      Template_PaidLandingPageFullWidth: PaidLandingPageFullWidth,
      Template_Membership: Membership,
      Template_NoSidebar: NoSidebar,
      Template_CleanLandingPageWithTopNav: CleanLandingPageWithTopNav,
      Template_FooterAndHeaderOnly: FooterAndHeaderOnly,
      DefaultTemplate: WpDefaultPageTemplate,
    };

    const template = data?.contentNode?.template?.__typename;
    const TemplateComponent =
      typeof template === 'string'
        ? pageComponentMap[template] || WpDefaultPageTemplate
        : WpDefaultPageTemplate;
    const { contentNode: page, sidebar } = data;
    return (
      <PreviewWrapper data={data}>
        <TemplateComponent data={{ page: page }} pageContext={{ sidebar }} />
      </PreviewWrapper>
    );
  }

  return null;
};

export default Preview;
